"use client";
import { Box, Container, Paper, Typography } from "@mui/material";
import Hls from "hls.js";
import { useEffect, useRef, useState } from "react";

export default function LivestreamPlayer({ containerMaxWidth = "lg", autoPlay }: { containerMaxWidth?: "lg" | "md"; autoPlay: boolean }) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const videoSrc = "https://livestream1.happydogs.no/stream/demo1/channel/0/hlsll/live/index.m3u8";

    if (Hls.isSupported()) {
      const hls = new Hls({
        liveSyncDurationCount: 3,
        liveMaxLatencyDurationCount: 5,
        maxBufferLength: 5,
        maxBufferSize: 2 * 1000 * 1000,
        liveDurationInfinity: true,
        lowLatencyMode: true,
      });
      hls.loadSource(videoSrc);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.ERROR, (_, data) => {
        if (data.fatal) {
          setError("Failed to load video stream");
        }
      });
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = videoSrc;
      video.onerror = () => {
        setError("Failed to load video stream");
      };
    } else {
      setError("Your browser doesn't support HLS video playback");
    }
  }, []);

  if (error) return <VideoError error={error} />;

  return (
    <Container maxWidth={containerMaxWidth} sx={{ py: 4 }}>
      <Paper
        elevation={3}
        sx={{
          overflow: "hidden",
          borderRadius: 2,
          bgcolor: "background.paper",
          position: "relative",
          mb: 4,
        }}
      >
        <video
          ref={videoRef}
          autoPlay={autoPlay}
          playsInline
          controls
          style={{
            width: "100%",
            display: "block",
            aspectRatio: "16/9",
          }}
        >
          <p>Fallback</p>
        </video>
      </Paper>
    </Container>
  );
}

const VideoError = ({ error }: { error: string }) => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper
        elevation={3}
        sx={{
          p: 6,
          textAlign: "center",
          borderRadius: 2,
          bgcolor: "background.paper",
          maxWidth: 600,
          mx: "auto",
        }}
      >
        <Box sx={{ mb: 3 }}>
          <img
            src="/images/redPaws.svg"
            alt="Error"
            style={{
              height: "auto",
              maxWidth: "120px",
              marginBottom: "24px",
            }}
          />
        </Box>
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: "medium", color: "error.main" }}>
          Oi! Noe gikk galt med videoen
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
          {error}
        </Typography>
      </Paper>
    </Container>
  );
};
