import { SelectProps } from "@mui/base";
import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import { useState } from "react";
import Kommuner from "./kommune.json" assert { type: "json" };

export type Kommune = {
  Fylkesnummer: string;
  Fylkesnamn: string;
  Kommunenummer: string;
  Kommunenamn: string;
  "Kommunenummer 2023": string;
};
interface CustomSelectProps extends SelectProps<any, boolean> {
  setKommune: (value: Kommune) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const KommuneSelect = ({
  setKommune,
  renderInput = (params) => (
    <TextField name={props.name} required={props.required} {...params} label="Kommune" placeholder="Søk etter kommune" />
  ),
  ...props
}: CustomSelectProps) => {
  const [currentKommune, setCurrentKommune] = useState<Kommune | undefined>(
    props.value
      ? Kommuner.find((kommune) => kommune.Kommunenamn === props.value.split(", ")[0] && kommune.Fylkesnamn === props.value.split(", ")[1])
      : undefined,
  );

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option) => option.Kommunenamn + ", " + option.Fylkesnamn}
      renderInput={renderInput}
      options={Kommuner}
      placeholder="Søk etter kommune"
      defaultValue={currentKommune}
      onChange={(event, newValue: Kommune) => {
        setKommune(newValue);
      }}
    />
  );
};

export default KommuneSelect;
