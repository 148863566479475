import { Replay } from "@mui/icons-material";
import { Autocomplete, Box, Button, ButtonBase, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useRouter } from "next/navigation";
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import { CiImageOn } from "react-icons/ci";
import CustomToggleButton from "../../../CustomToggleButton";
import Uploader from "../../../ImageUpload";
import KommuneSelect from "../../../KommuneSelect";
import BreedingGuildelines from "../../../LitterProfile/BreedingGuidelines";
import { tan } from "../../../Theme";
import { useGetPrivateMemberDetailsEndpoint, useGetPrivateMemberDogsEndpoint } from "../../../generated/happydogsComponents";
import { DogView, LitterPrivateDetailsView, RegisterLitterEndpointCommand } from "../../../generated/happydogsSchemas";
import { useObjectUrl } from "../../../handleObject";

export type RegisterLitterFn = (
  command: Omit<RegisterLitterEndpointCommand, "breederId" | "memberId" | "pupppies">,
  memberId: string,
  breederId: string,
  location: string,
) => Promise<void | null>;

interface AddLitterProps {
  newLitter: Partial<RegisterLitterEndpointCommand>;
  setNewLitter: (litter: Partial<RegisterLitterEndpointCommand>) => void;
  setProfileImage: (file: File) => unknown;
  profileImage: File;
  memberId: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setShowAddDog: Dispatch<SetStateAction<boolean>>;
}

const AddLitterRegistrationForm = ({
  newLitter,
  setNewLitter,
  setProfileImage,
  profileImage,
  memberId,
  setOpen,
  setShowAddDog,
}: AddLitterProps) => {
  const [isBorn, setIsBorn] = useState(false);
  const [nkk, setNkk] = useState(false);
  const [breedClub, setBreedClub] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);

  const router = useRouter();

  const { data: member } = useGetPrivateMemberDetailsEndpoint({});

  const {
    data: dogs,
    refetch: dogRefetch,
    isLoading,
  } = useGetPrivateMemberDogsEndpoint({ pathParams: { memberId: memberId! } }, { enabled: !!memberId });

  const src = useObjectUrl(profileImage);

  const handleInputChange = (key: keyof LitterPrivateDetailsView, newValue: string | null | boolean) => {
    setNewLitter({ ...newLitter, [key]: newValue });
  };

  useEffect(() => {
    handleInputChange("meetsClubBreedingRequirement", breedClub);
    handleInputChange("meetsNkkBreedingRequirements", nkk);
  }, [nkk, breedClub]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          paddingTop: "20px",
        }}
      >
        <Typography variant="h2">Registrer nytt kull</Typography>

        <Box sx={{ display: "flex", gap: { xs: "25px", md: "30px" }, flexDirection: { xs: "column", md: "row" } }}>
          <ColumnWrapper paddingTop={"18px"} gap="25px">
            <TextField
              required
              fullWidth
              value={newLitter.litterName ?? ""}
              onChange={(e) => handleInputChange("litterName", e.target.value)}
              label="Kullnavn"
              onInput={(e: FormEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;

                const reg = new RegExp(/\w{3,}.*/);

                if (reg.test(target.value)) {
                  target.setCustomValidity("");
                } else {
                  target.setCustomValidity("Navnet må inneholde minst 3 bokstaver");
                }
              }}
            />

            <KommuneSelect name="location" setKommune={() => void 0} value={member?.location} />

            <div style={{ display: "flex" }}>
              <Autocomplete
                fullWidth
                options={dogs?.filter((dog) => !dog.isMale) ?? []}
                loading={isLoading}
                value={dogs?.find((dog) => dog.registryId === newLitter.motherRegistryId)}
                sx={{ width: "100%" }}
                isOptionEqualToValue={(option, value) => option.registryId === value.registryId}
                getOptionLabel={(option: DogView) => option.registryName}
                disableClearable
                onChange={(_, newValue) => handleInputChange("motherRegistryId", newValue.registryId)}
                renderInput={(params) => (
                  <TextField
                    helperText={
                      <ButtonBase onClick={() => setShowAddDog(true)} style={{ textDecoration: "underline" }}>
                        Finner du ikke mor? Legg til ny hund her
                      </ButtonBase>
                    }
                    {...params}
                    label="Mor"
                    required
                  />
                )}
              />
              <Tooltip title="Oppdater hunder">
                <IconButton onClick={() => dogRefetch()}>
                  <Replay />
                </IconButton>
              </Tooltip>
            </div>
            <TextField
              value={newLitter.fatherRegistryId ?? ""}
              onChange={(e) => handleInputChange("fatherRegistryId", e.target.value)}
              label="Far (Registreringsnummer)"
              sx={{ marginBottom: { md: "25px" } }}
              fullWidth
            />
          </ColumnWrapper>
          <ColumnWrapper gap={{ md: "0px", xs: "25px" }}>
            <Stack gap={"25px"}>
              <CustomToggleButton
                leftValue="Ja"
                rightValue="Nei"
                label="Født"
                value={isBorn ? "Ja" : "Nei"}
                setSelectedResult={(value: string) => {
                  setIsBorn(value === "Ja");
                  if (value === "Nei") {
                    handleInputChange("term", null);
                  }
                }}
              />
              {isBorn && (
                <>
                  <Box display={"flex"} width={"100%"} gap="20px">
                    <DatePicker
                      format="DD.MM.YYYY"
                      label="Født"
                      maxDate={dayjs()}
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                      value={newLitter.term ? dayjs(newLitter.term) : null}
                      onChange={(date: Dayjs) => handleInputChange("term", date ? date.toISOString() : null)}
                      sx={{ width: "100%" }}
                    />
                    <DatePicker
                      format="DD.MM.YYYY"
                      label="Leveringsklare"
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                      value={newLitter.deliveryReadyAt ? dayjs(newLitter.deliveryReadyAt) : null}
                      onChange={(date) => handleInputChange("deliveryReadyAt", date ? date.toISOString() : null)}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                </>
              )}
            </Stack>
            <Box mt={{ md: "10px", xs: 0 }}>
              <CustomToggleButton
                label="Tilgjengelige valper"
                leftValue={"Tilgjengelig"}
                rightValue={"Ikke tilgjengelig"}
                value={newLitter.available ? "Tilgjengelig" : "Ikke tilgjengelig"}
                setSelectedResult={(e) => {
                  setNewLitter({
                    ...newLitter,
                    available: e === "Tilgjengelig",
                  });
                }}
              />
            </Box>
            <Box mt={{ md: isBorn ? "40px" : "20px", xs: 0 }} mb={{ md: "25px", xs: 0 }}>
              <BreedingGuildelines nkk={nkk} setNkk={setNkk} breedClub={breedClub} setBreedclub={setBreedClub} />
            </Box>
            <ButtonBase
              sx={{
                height: "80%",
                border: `2px dotted ${tan[50]}`,
                maxWidth: "100%",
                width: profileImage ? "fit-content" : "100%",
                borderRadius: "10px",
              }}
              onClick={() => setUploadImage(true)}
            >
              <Box position={"absolute"}>
                {!profileImage && <CiImageOn color={tan[300]} fontSize={"30px"} />}
                <Typography variant="subtitle2">{profileImage ? "Endre bilde" : "Last opp bilde"}</Typography>
              </Box>
              <Box height={"120px"} maxWidth={"100%"}>
                <img
                  style={{ display: "flex", width: "100%", height: "120px", objectFit: "cover", borderRadius: "10px", opacity: 0.4 }}
                  alt=""
                  src={src}
                />
              </Box>
            </ButtonBase>
          </ColumnWrapper>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Button onClick={() => setOpen(false)} variant="outlined" color="secondary" type="reset">
            Avbryt
          </Button>
          <Button type="submit" disabled={isLoading} variant="contained">
            Legg til kull
          </Button>
        </Box>
      </Box>
      <Uploader
        id="litterInfo"
        onUpload={(file: File, _ = false) => Promise.resolve(setProfileImage(file))}
        uploadImage={uploadImage}
        handleClose={() => setUploadImage(false)}
        setProfilePicture={true}
        closeAfterUpload={true}
      />
    </>
  );
};

export default AddLitterRegistrationForm;

const ColumnWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
