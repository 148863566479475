"use client";

import { Box, Card, SvgIcon, ToggleButtonGroup, Typography, styled } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { breederIcon, dogface, litterIcon } from "../../public/assets/icons";
import { Type } from "../enum";
import { StyledToggleButton } from "../FilterButtonGroup";
import { useGetPrivateMemberDetailsEndpoint } from "../generated/happydogsComponents";
import SearchBar from "../Searchbar";
import { tan } from "../Theme";

interface Props {
  urlHref: string;
  iconSrc: string;
  description: string;
}

const ActionButton = ({ urlHref, iconSrc, description }: Props) => {
  return (
    <a href={urlHref}>
      <Card
        sx={{
          width: "100%",
          flexDirection: "row",
          height: "110px",

          display: "flex",
          border: `1px solid ${tan[50]}`,
          boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          "&:hover": {
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F0ECE7",
            width: "40%",
            alignContent: "center",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconImage src={iconSrc} />
        </Box>
        <Box width={"60%"} display={"flex"} justifyContent={"center"} alignItems={"center"} justifyItems={"center"} padding={"15px"}>
          <Typography variant="subtitle2" textAlign={"center"}>
            {description}
          </Typography>
        </Box>
      </Card>
    </a>
  );
};

const IconImage = styled("img")`
  height: 80%;
  padding: 10%;
`;

interface PageProps {
  isLoggedin: boolean;
  isBreeder: boolean;
}

const MobileWelcome = ({ isLoggedin, isBreeder }: PageProps) => {
  const router = useRouter();
  const [name, setName] = useState<string | undefined>(undefined);
  const { data: member, isLoading: isLoadingMember } = useGetPrivateMemberDetailsEndpoint({}, { enabled: isLoggedin });

  useEffect(() => {
    if (isLoggedin && member && member.name) setName(member?.name);
  }, [member]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap="40px" padding={"20px"} pb={"30px"}>
      <Box display={"flex"} flexDirection={"column"} gap="10px">
        <Typography variant="h1" pt={"20px"}>
          Velkommen til HappyDogs{name ? `, ${name}!` : "!"}
        </Typography>
        <SearchBar variant="Landingpage" focus={false} />
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap="20px">
        <Typography variant="h2">Hva ser du etter?</Typography>

        <ToggleButtonGroup
          exclusive
          sx={{ display: "flex", justifyContent: { xs: "space-between", sm: "start" }, width: "100%", gap: { xs: 0, sm: "20px" } }}
        >
          <StyledToggleButton value={Type.Breeder} onClick={() => router.replace("/search?type=Breeder")} LinkComponent={Link}>
            <Box>
              <SvgIcon sx={{ fontSize: { md: 64, xs: 48 } }} color="primary" inheritViewBox component={breederIcon} />
              <Typography> Oppdrettere </Typography>
            </Box>
          </StyledToggleButton>
          <StyledToggleButton value={Type.Dog} onClick={() => router.replace("/search?type=Dog")} LinkComponent={Link}>
            <Box>
              <SvgIcon sx={{ fontSize: { md: 64, xs: 48 } }} color="primary" inheritViewBox component={dogface} />
              <Typography> Hunder </Typography>
            </Box>
          </StyledToggleButton>
          <StyledToggleButton value={Type.Litter} onClick={() => router.replace("/search?type=Litter")} LinkComponent={Link}>
            <Box>
              <SvgIcon sx={{ fontSize: { md: 64, xs: 48 } }} color="primary" inheritViewBox component={litterIcon} />
              <Typography> Valpekull </Typography>
            </Box>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap="20px">
        <Typography variant="h2">Kom i gang</Typography>
        <ActionButton
          iconSrc={isBreeder ? "/assets/icons/breeder.svg" : "/assets/icons/dogface.svg"}
          description={isBreeder ? "Registrer ditt oppdrett, hunder og valpekull" : "Presenter dine hunder med egne profiler"}
          urlHref={isLoggedin ? (isBreeder ? "/me/breeder" : "/me/dogs") : "/login"}
        />
        <ActionButton
          iconSrc={"/assets/icons/puppylog.svg"}
          description="Før treningslogg og valpelogg"
          urlHref={isLoggedin ? (isBreeder ? "/me/log" : "/me/log/doglog") : "/login"}
        />
        <ActionButton
          iconSrc={"/assets/icons/waitinglist.svg"}
          description="Administrer spørreskjema og venteliste"
          urlHref={isLoggedin ? (isBreeder ? "/me/waitinglist" : "/me/applications") : "/login"}
        />
      </Box>
    </Box>
  );
};

export default MobileWelcome;
